<template>
  <v-container fluid>
    <div class="d-flex flex-wrap justify-space-between">
      <div class="graph text-right" id="div1000">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph1000"></canvas>
        <v-icon @click="extend(1000,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div2000">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph2000"></canvas>
        <v-icon @click="extend(2000,$event.target)">mdi-fullscreen</v-icon>
      </div>
      <div class="graph text-right" id="div3000">
        <v-skeleton-loader type="image" v-if="loading"></v-skeleton-loader>
        <canvas v-show="!loading" id="graph3000"></canvas>
        <v-icon @click="extend(3000,$event.target)">mdi-fullscreen</v-icon>
      </div>
    </div>
  </v-container>
</template>

<script>
import { parseDate } from "@/utils";
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  data() {
    return {
      loading: null,
      resumen: [],
      chart1: {},
      chart2: {},
      specialColorBorder:{
        success: 'rgba(76, 175, 80, 1)',
        info: 'rgba(54, 162, 235, 1)',
        warning: 'rgba(251, 140, 0, 1)',
        error: 'rgba(255, 82, 82, 1)'
      },
      specialColor:{
        success: 'rgba(76, 175, 80, 0.2)',
        info: 'rgba(54, 162, 235, 0.2)',
        warning: 'rgba(251, 140, 0, 0.2)',
        error: 'rgba(255, 82, 82, 0.2)'
      },
      borderColors:[
        'rgba(54, 162, 235, 1)',
        'rgba(181, 20, 66, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(255, 205, 86, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(0, 0, 207, 1)',
        'rgba(64, 255, 88, 1)',
      ],
      colors:[
        'rgba(54, 162, 235, 0.2)',
        'rgba(181, 20, 66, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(0, 0, 207, 0.2)',
        'rgba(64, 255, 88, 0.2)',
      ]
    };
  },
  computed:{
    resumenComp(){
      return this.resumen.filter(r => r.fecha <= new Date().getFullYear()).slice(-5)
    },
    backdrop(){
      if (this.$vuetify.theme.isDark) return '#1E1E1E'
      else return '#FFF'
    },
    grid(){
      if (this.$vuetify.theme.isDark) return '#d39652'
    }
  },
  mounted(){
    this.loading = true
    this.getEventos()
  },
  methods: {
    extend(e,t){
      if (t.getAttribute("class").split(" ").includes("mdi-fullscreen")) {
        t.setAttribute("class", t.getAttribute("class").replace("mdi-fullscreen", "mdi-fullscreen-exit"))
        document.getElementById("div" + e).setAttribute("class",document.getElementById("div" + e).getAttribute("class").replace("graph", "graphExtended"));
      }
      else{
        t.setAttribute("class", t.getAttribute("class").replace("mdi-fullscreen-exit", "mdi-fullscreen"))
        document.getElementById("div" + e).setAttribute("class",document.getElementById("div" + e).getAttribute("class").replace("graphExtended", "graph"));  
      }
    },  
    parseDate,
    async getEventos() {
      const resumen = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/eventototal`,
      });

      this.loading = false

      this.resumen = [...this.resumen,...resumen.data];

      const self = this

      this.chart1 = new Chart(document.getElementById("graph1000"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'CONF',
              tension: 0.25,
              data: [null,...this.resumenComp.map(r => r.eventos),null],
              backgroundColor: [
                this.specialColor['success'],
              ],
              borderColor: [
                this.specialColorBorder['success'],
              ],
              fill: true,
              backgroundColor: this.specialColor['success'],
              borderWidth: 1,
              datalabels: {
                labels:{
                  index:{
                    color: this.specialColorBorder['success'],
                    anchor: 'end',
                    align: 'top',
                    clamp: true,
                    offset: -5,
                  },
                  name:{
                    color: this.specialColorBorder['success'],
                    anchor: 'end',
                    align: 'bottom',
                    clamp: true,
                    formatter: function(value, context) {
                      return Math.round(parseFloat(value / Math.max(parseInt((self.resumenComp.slice(2018-self.resumenComp.map(r => r.fecha).sort().reverse()[0])[context.dataIndex-1]?.eventos||0)+(self.resumenComp.slice(2018-self.resumenComp.map(r => r.fecha).sort().reverse()[0])[context.dataIndex-1]?.perdidas||0)),1))*100) + '%'
                    }
                  }
                },
              },
            },
            {
              label: 'PERD',
              tension: 0.25,
              data: [null,...this.resumenComp.map(r => r.perdidas),null],
              backgroundColor: [
                this.specialColor['error'],
              ],
              borderColor: [
                this.specialColorBorder['error'],
              ],
              fill: true,
              backgroundColor: this.specialColor['error'],
              borderWidth: 1,
              datalabels: {
                color: this.specialColorBorder['error'],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins:{
            title: {
              color: '#d39652',
              display: true,
              text: 'TOTAL EVENTOS',
              position:'top',
              align:'center',
              font:{
                size: 24
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            },
          },
        },
        plugins: [ChartDataLabels],
      })

      this.chart2 = new Chart(document.getElementById("graph2000"), {
        type: 'line',
        data: {
          labels: [null,...this.resumenComp.map(r => r.fecha),null],
          datasets: [
            {
              label: 'PIS',
              data: [null,...this.resumenComp.map(r => r.piscis),null],
              backgroundColor: [
                this.colors[0],
              ],
              borderColor: [
                this.borderColors[0],
              ],
              fill: false,
              backgroundColor: this.colors[0],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[0],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'HOT',
              data: [null,...this.resumenComp.map(r => r.hotel),null],
              backgroundColor: [
                this.colors[1],
              ],
              borderColor: [
                this.borderColors[1],
              ],
              fill: false,
              backgroundColor: this.colors[1],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[1],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LAL',
              data: [null,...this.resumenComp.map(r => r.lalanne),null],
              backgroundColor: [
                this.colors[2],
              ],
              borderColor: [
                this.borderColors[2],
              ],
              fill: false,
              backgroundColor: this.colors[2],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[2],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LAUS',
              data: [null,...this.resumenComp.map(r => r.laus),null],
              backgroundColor: [
                this.colors[3],
              ],
              borderColor: [
                this.borderColors[3],
              ],
              fill: false,
              backgroundColor: this.colors[3],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[3],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'TOZ&PAL',
              data: [null,...this.resumenComp.map(r => r.toz + r.palacio,null)],
              backgroundColor: [
                this.colors[4],
              ],
              borderColor: [
                this.borderColors[4],
              ],
              fill: false,
              backgroundColor: this.colors[4],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[4],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'ARAGÓN',
              data: [null,...this.resumenComp.map(r => r.catAra),null],
              backgroundColor: [
                this.colors[5],
              ],
              borderColor: [
                this.borderColors[5],
              ],
              fill: false,
              backgroundColor: this.colors[5],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[5],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            {
              label: 'LLEIDA',
              data: [null,...this.resumenComp.map(r => r.catCat),null],
              backgroundColor: [
                this.colors[5],
              ],
              borderColor: [
                this.borderColors[5],
              ],
              fill: false,
              backgroundColor: this.colors[5],
              borderWidth: 1,
              datalabels: {
                color: this.borderColors[5],
                anchor: 'end',
                align: 'top',
                clamp: true,
                offset: -5
              },
            },
            // {
            //   label: 'LLEIDA',
            //   data: this.resumenComp.map(r => r.oCat),
            //   backgroundColor: [
            //     this.colors[6],
            //   ],
            //   borderColor: [
            //     this.borderColors[6],
            //   ],
            //   fill: false,
            //   backgroundColor: this.colors[6],
            //   borderWidth: 1,
            //   datalabels: {
            //     color: this.borderColors[6],
            //     anchor: 'end',
            //     align: 'top',
            //     clamp: true,
            //     offset: -5
            //   },
            // }
          ]
        },
        options: {
          maintainAspectRatio: false,
          plugins:{
            legend:{
              labels:{
                boxWidth: 15
              }
            },
            title: {
              color: '#d39652',
              display: true,
              text: 'ESPACIOS CELEBRACIÓN (%)',
              position:'top',
              align:'center',
              font:{
                size: 24
              }
            }
          },
          scales: {
            y: {
              beginAtZero: true
            }, 
          },
        },
        plugins: [ChartDataLabels],
      })
      
      var ds = []
      this.resumenComp.forEach((r,i) => {
        var tot = r.negativa + r.neutra + r.positiva
        ds.push({ 
          label : r.fecha,
          data : [(r.negativa / tot) * 100, (r.neutra / tot) * 100, (r.positiva / tot) * 100],
          backgroundColor: this.colors[i],
          borderColor: this.borderColors[i],
          pointBackgroundColor: this.colors[i],
          pointBorderColor: this.borderColors[i],
          pointHoverBackgroundColor: this.colors[i],
          pointHoverBorderColor: this.borderColors[i],
          tooltip: {
            callbacks: {
              label: function(context) {
                return r.fecha + ': ' + context.formattedValue + '%'
              }
            }
          }
         })
      });

      this.chart3 = new Chart(document.getElementById("graph3000"), {
        type: 'radar',
        data: {
          labels: ["Valoración negativa", "Valoración neutra", "Valoración positiva"],
          datasets: ds
        },
        options:{
          plugins: {
            title: {
              color: '#d39652',
              display: true,
              text: 'VALORACIÓN (%)',
              position: 'top',
              align:'center',
              font:{
                size: 24
              }
            }
          },
          scales:{
            r:{
              ticks:{
                backdropColor: this.backdrop
              },
              angleLines:{
                color: this.angleLines
              },
              grid:{
                color: this.grid
              }
            }
          }
        }
      })
    }
  },
}
</script>
<style>
.graph {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  transition: all .5s ease-in-out;
  max-height: 400px; max-width: calc(100%/3.05) !important;
}
.graphExtended {
  /* border: 1px solid var(--v-secondary-lighten5); */
  cursor: pointer;
  transition: all .5s ease-in-out;
  height: 100% !important; width: 100% !important;
}
</style>